/* eslint-disable camelcase */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { setFiltersActive } from '../../../redux';
import getLastSlugFromChips from '../../../services/filters/getLastSlugFromChips';
import routerPushWithQuery from '../../../services/filters/routerPushWithQuery';
import styles from './filter-chips.module.scss';

const getSelectedChips = (arr, type, handleClick) => {
   return arr.map((x) => {
      if (x.selected) {
         return (
            <Chip key={x.slug} type={type} value={x.slug} label={x.name} onClick={handleClick} />
         );
      }

      return null;
   });
};

const Chip = (props) => {
   const { type, value, label, onClick } = props;

   return (
      <li>
         <button className={styles.chip} onClick={() => onClick(type, value)}>
            {label} <MdClose size={14} />
         </button>
      </li>
   );
};

export default function FilterChips(props) {
   const { filters, filtersOptions, orderBy } = props;
   const { genero, slugs, marca, deporte, talle, landing, keywords } = filters;
   const dispatch = useDispatch();
   const handleClick = (name, value) => {
      let newFiltersActive;

      if (name === 'genero') {
         newFiltersActive = {
            genero: '',
            slugs: [],
            marca: [],
            deporte: [],
            talle: [],
            last_slug: genero,
            landing,
         };
      }

      if (name === 'slugs') {
         newFiltersActive = {
            ...filters,
            slugs: slugs.filter((x) => x !== value),
            marca: [],
            deporte: [],
            talle: [],
            last_slug: getLastSlugFromChips(filters, slugs, name, value),
         };
      }
      if (value === keywords) {
         newFiltersActive = {
            ...filters,
            keywords: '',
            last_slug: getLastSlugFromChips(filters, keywords, name, value),
         };
      } else {
         if (name === 'marca') {
            newFiltersActive = {
               ...filters,
               slugs: slugs.filter((x) => x !== value),
               marca: marca.filter((x) => x !== value),
               deporte: [],
               talle: [],
               last_slug: getLastSlugFromChips(filters, marca, name, value),
            };
         }

         if (name === 'deporte') {
            newFiltersActive = {
               ...filters,
               slugs: slugs.filter((x) => x !== value),
               deporte: deporte.filter((x) => x !== value),
               talle: [],
               last_slug: getLastSlugFromChips(filters, deporte, name, value),
            };
         }
      }

      if (name === 'talle') {
         newFiltersActive = {
            ...filters,
            talle: talle.filter((x) => x !== value),
            last_slug: getLastSlugFromChips(filters, talle, name, value),
         };
      }
      dispatch(setFiltersActive(newFiltersActive));
      routerPushWithQuery(newFiltersActive, orderBy);
   };

   const renderChips = () => {
      const arr = [];
      const types = {
         size: 'talle',
         gender: 'genero',
         brand: genero ? 'slugs' : 'marca',
         sport: genero ? 'slugs' : 'deporte',
      };
      Object.keys(filtersOptions).forEach((option) => {
         arr.push(
            ...getSelectedChips(
               filtersOptions[option].options,
               types[option] || 'slugs',
               handleClick,
            ),
         );
      });

      return arr;
   };

   return (
      <div className={styles.container}>
         <ul>{renderChips()}</ul>
      </div>
   );
}
