import React from 'react';
import { useSelector } from 'react-redux';
import Pagination from '../Pagination';
import FilterChips from '../Products/FilterChips';
import ProductItem from './ProductItem/ProductItem';
import ProductsSkeleton from './ProductsSkeleton';
import styles from './product-list.module.scss';

function ProductList() {
   const { loading, items, error, filtersActive, filtersOptions, orderBy, pagination } =
      useSelector((state) => state.products);

   const renderItems = () => {
      return items.map((item) => <ProductItem key={item.sku} item={item} lazy />);
   };
   return (
      <div className={styles.container}>
         <div className={styles.chipList}>
            {filtersActive && filtersOptions && items.length > 0 && (
               <FilterChips
                  filters={filtersActive}
                  filtersOptions={filtersOptions}
                  orderBy={orderBy}
               />
            )}
         </div>

         {error && <span>{error}</span>}

         <ul className={styles.productList}>
            {loading && <ProductsSkeleton />}
            {!loading && !error && renderItems()}
         </ul>

         {pagination.total >= 1 && (
            <div className={styles.pagination}>
               <Pagination pagination={pagination} />
            </div>
         )}
      </div>
   );
}

export default ProductList;
