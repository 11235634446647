/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import cn from 'classnames';
import { MdTune, MdSwapVert } from 'react-icons/md';
import { useSelector } from 'react-redux';
import Filter from './Filter';
import Sort from './Sort';
import styles from './filter-sort.module.scss';

const ButtonAction = ({ label, icon, onClick }) => {
   return (
      <button className={styles.button} onClick={onClick}>
         {icon}
         <span>{label}</span>
      </button>
   );
};

export default function FilterSort(props) {
   const { filtersActive } = useSelector((state) => state.products);
   const [showFilter, setShowFilter] = useState(false);
   const [showSort, setShowSort] = useState(false);
   const isTablet = useMediaQuery('(max-width: 768px)');
   useEffect(() => {
      if (showFilter) {
         setShowFilter(false);
      }
      if (showSort) {
         setShowSort(false);
      }
   }, [filtersActive]);

   const toggle = (type) => {
      switch (type) {
         case 'FILTER':
            setShowFilter(!showFilter);
            break;
         case 'SORT':
            setShowSort(!showSort);
            break;
         default:
      }
   };

   const classFilter = cn({
      [props.classFilter]: true,
      [styles.visible]: showFilter,
   });

   const classSort = cn({
      [props.classSort]: true,
      [styles.visible]: showSort,
   });

   return (
      <>
         <div className={styles.wrapperButtons}>
            <ButtonAction
               label="Filtrar"
               icon={<MdTune size={20} />}
               onClick={() => toggle('FILTER')}
            />
            <ButtonAction
               label="Ordenar"
               icon={<MdSwapVert size={20} />}
               onClick={() => toggle('SORT')}
            />
         </div>
         {isTablet && <h1 className={styles.title}>{props.title}</h1>}
         <div className={classFilter}>
            <Filter isTablet={isTablet} title={props.title} onClose={() => toggle('FILTER')} />
         </div>
         <div className={classSort}>
            <Sort onClose={() => toggle('SORT')} />
         </div>
      </>
   );
}
