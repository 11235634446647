/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import useDisabledPagination from '../../../hooks/useDisabledPagination';
import getPageNumber from '../../../services/pagination/getPageNumber';
import styles from '../pagination.module.scss';

const PaginationButton = (props) => {
   const { icon, type, onClick } = props;
   const router = useRouter();
   const { current, total } = useSelector((state) => state.products.pagination);
   const disabled = useDisabledPagination(type, current, total);
   const page = getPageNumber(current, total, type);
   const { asPath } = router;

   const classItemDisabled = cn({
      [styles.item]: true,
      [styles['item--disabled']]: true,
   });
   delete router.query.slugs;

   return (
      <li>
         {!disabled && (
            <Link href={asPath.replace(/\/p\/\d+/, `/p/${page}`)}>
               <a className={styles.item} onClick={() => onClick(type)}>
                  {icon}
               </a>
            </Link>
         )}

         {disabled && <div className={classItemDisabled}>{icon}</div>}
      </li>
   );
};

export default PaginationButton;
