import { useEffect, useState } from 'react';

const useDisabledPagination = (type, current, total) => {
   const [disabled, setDisabled] = useState(false);

   useEffect(() => {
      switch (type) {
         case 'NEXT':
            setDisabled(current >= total);
            break;

         case 'MORE_NEXT':
            setDisabled(current >= total);
            break;

         case 'BACK':
            setDisabled(current <= 1);
            break;

         case 'MORE_BACK':
            setDisabled(current <= 1);
            break;

         default:
            break;
      }
   }, [current]);

   return disabled;
};

export default useDisabledPagination;
