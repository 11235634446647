/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import Brand from 'Brand';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import Badge from '../../../components/Badge';
import useMouseOver from '../../../hooks/useMouseOver';
import generateHrefLink from '../../../services/page/generateLinkHref';
import themes from '../../../themes';
import styles from './product-item.module.scss';

const Blurhash = dynamic(() => import('../../../components/Blurhash'));

const ProductItem = (props) => {
   const {
      item: {
         name,
         url,
         all_prices: price,
         gender,
         brand_name: brandName,
         brand_icon: brandIcon,
         featured,
         dummy_images: images,
      },
      lazy,
      withQuickView = false,
      handleQuickViewClick,
   } = props;

   const router = useRouter();
   const imageRef1 = useRef(null);
   const imageRef2 = useRef(null);
   const imageWrapperRef = useRef(null);
   const [imageLoaded, setImageLoaded] = useState(false);
   const [onOver, setOnOver] = useState(false);
   const [viewRef, inView] = useInView({
      threshold: 0,
      rootMargin: '0px',
      triggerOnce: true,
   });

   const theme = useSelector((state) => state.theme.name);
   // hook para detectar cuando el mouse esta por encima de la imagen
   useMouseOver(imageWrapperRef, (isOver) => {
      if (images[1]) {
         setOnOver(isOver);
      }
   });

   // detectar cuando la imagen2 ya cargo
   useEffect(() => {
      if (imageRef2 && imageRef2.current?.complete) {
         setImageLoaded(true);
      }
   }, [imageRef2]);

   const classImage = cn({
      [styles.image]: true,
      // con esto ocultamos la imagen 1
      [styles['image--hide']]: onOver,
   });
   const handleClickLink = () => {
      document.cookie = `idColor=${url.replace(/.*-(\d+)$/, '$1')}; max-age=${7200}; path=/`;
   };
   const renderImg = () => {
      const imgFragment = (
         <>
            {images[1] && (
               <img ref={imageRef1} src={images[1]?.url} alt={name} className={styles.image} />
            )}
            <img
               ref={imageRef2}
               src={images[0]?.url}
               alt={name}
               className={classImage}
               onLoad={() => setImageLoaded(true)}
            />
         </>
      );
      if (withQuickView) {
         return (
            <a
               role="button"
               href="#"
               className={styles.imageWrapper}
               onClick={handleQuickViewClick}
            >
               {imgFragment}
            </a>
         );
      }
      return (
         <Link href={generateHrefLink(url.replace(/-(\d+)$/, ''), router)}>
            <a
               onClick={handleClickLink}
               onContextMenu={handleClickLink}
               className={styles.imageWrapper}
            >
               {imgFragment}
            </a>
         </Link>
      );
   };

   const renderProductTitle = () => {
      if (withQuickView) {
         return (
            <a className={styles.name} onClick={handleQuickViewClick} role="button" href="#">
               <h2>{name}</h2>
            </a>
         );
      }
      return (
         <Link href={generateHrefLink(url.replace(/-(\d+)$/, ''), router)}>
            <a onClick={handleClickLink} onContextMenu={handleClickLink} className={styles.name}>
               <h2>{name}</h2>
            </a>
         </Link>
      );
   };
   return (
      <li ref={viewRef} className={styles.container}>
         {(inView || !lazy) && (
            <div className={styles.wrapper}>
               <div className={styles.discountBrand}>
                  {price.discount && (
                     <div className={styles.discount}>
                        <span translate="no">{price.discount}% OFF</span>
                     </div>
                  )}

                  {brandIcon && (
                     <div className={styles.brand}>
                        <Brand brandIcon={brandIcon.url} brandName={brandName} />
                     </div>
                  )}
               </div>
               {renderImg()}

               {!imageLoaded && (
                  <div className={styles.placeholderBlur}>
                     <Blurhash hash={images[0]?.blurhash} />
                  </div>
               )}

               {/* {!imageLoaded && <div className={styles.placeholder} />} */}
               <div className={styles.details}>
                  <div className={styles.genderFeatured}>
                     <span className={styles.gender}>{gender}</span>
                     {featured && (
                        <div className={styles.featured}>
                           <Badge
                              color={featured?.text_color || themes[theme]['color-prod-detail-badge-text-principal']}
                              backgroundColorLeft={featured?.background_color_left || themes[theme]['color-prod-detail-badge-bg-principal']}
                              backgroundColorRight={featured?.background_color_right || themes[theme]['color-prod-detail-badge-bg-principal']}
                              uppercase
                              featureSpecial
                           >
                              {featured.name}
                           </Badge>
                        </div>
                     )}
                  </div>

                  {renderProductTitle()}

                  <div className={styles.price}>
                     <span className={styles.currentPrice}>
                        ${price.sale_price || price.original}
                     </span>

                     {price.sale_price && (
                        <span className={styles.originalPrice}>${price.original}</span>
                     )}
                  </div>

                  {price.installments && (
                     <div className={styles.installments}>
                        <Badge text>
                           hasta {price.installments.quantity} x $ {price.installments.value} sin
                           interés
                        </Badge>
                     </div>
                  )}

                  {/* TODO: Obtener este valor de la API y no mostrar si es falso */}
               </div>
            </div>
         )}
      </li>
   );
};

export default ProductItem;
