/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import cn from 'classnames';
import styles from './filter-option-size.module.scss';

export default function FilterOptionSize(props) {
   const { name, onClick } = props;

   const [selected, setSelected] = useState(props.selected);

   const handleClick = () => {
      setSelected(!selected);
      onClick(selected);
   };

   const classContainer = cn({
      [styles.container]: true,
      [styles.checked]: selected,
   });

   return (
      <li className={classContainer}>
         <button onClick={handleClick}>
            <span className={styles.name}>{name}</span>
         </button>
      </li>
   );
}
