/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import cn from 'classnames';
import Link from 'next/link';
import styles from '../pagination.module.scss';

const PaginationItem = (props) => {
   const { page, active, router, onClick } = props;
   const { asPath } = router;

   const classItem = cn({
      [styles.item]: true,
      [styles['item--active']]: active,
   });
   delete router.query.slugs;
   return (
      <li>
         <Link href={asPath.replace(/\/p\/\d+/, `/p/${page}`)}>
            <a className={classItem} onClick={() => onClick(page)}>
               {page}
            </a>
         </Link>
      </li>
   );
};

export default PaginationItem;
