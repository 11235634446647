/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import cn from 'classnames';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderBy } from '../../../../redux';
import routerPushWithQuery from '../../../../services/filters/routerPushWithQuery';
import styles from './sort-item.module.scss';

export default function SortItem(props) {
   const { label, value, onClick } = props;
   const dispatch = useDispatch();
   const { filtersActive, orderBy, pagination } = useSelector((state) => state.products);
   const isMobile = useMediaQuery('(max-width: 768px)');
   const [active, setActive] = useState(false);

   useEffect(() => setActive(orderBy === value), [orderBy]);

   // al seleccionar una opcion de "ordenar por"
   const handleClick = () => {
      // si es mobile scrolleamos al inicio de la pagina
      if (isMobile) {
         window.scrollTo(0, 282);
      }

      // ejecutamos la accion de redux
      dispatch(setOrderBy(value));

      // hacemos el push a la nueva pagina con los filtros activados
      routerPushWithQuery(filtersActive, value, pagination.current);

      onClick();
   };

   const sortItemClass = cn({
      [styles.container]: true,
      [styles['container--active']]: active && !isMobile,
   });

   return (
      <li className={sortItemClass}>
         <button onClick={handleClick}>
            {!active && isMobile && <MdRadioButtonUnchecked />}
            {active && isMobile && <MdRadioButtonChecked />}
            {label}
         </button>
      </li>
   );
}
