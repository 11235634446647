/* eslint-disable camelcase */
import ImageNext from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import verifyMicrositio from '../../services/filters/verifyMicrositio';
import generateHrefLink from '../../services/page/generateLinkHref';
import styles from './banner-interactive.module.scss';

export default function BannerInteractive() {
   const router = useRouter();
   const banner = useSelector((state) => state.banners?.gallery?.[0]);
   const { desktop_image: desktopImage, mobile_image: mobileImage, alt_text } = banner || {};

   const renderChips = () => {
      if (banner?.chips) {
         return banner.chips.map((x) => (
            <li key={x.id}>
               <a href={generateHrefLink(x.url, router)} className={styles.chip}>
                  {x.name}
               </a>
            </li>
         ));
      }

      return [];
   };

   const Banners = () => (
      <div className={styles.container}>
         <ul className={styles.list}>{renderChips()}</ul>

         <div className={styles.mobile}>
            {mobileImage ? (
               <ImageNext
                  src={mobileImage.url}
                  layout="fill"
                  objectFit="cover"
                  quality={25}
                  alt={alt_text}
                  priority
               />
            ) : null}
         </div>

         <div className={styles.desktop}>
            {desktopImage ? (
               <ImageNext
                  src={desktopImage.url}
                  layout="fill"
                  objectFit="cover"
                  quality={25}
                  alt={alt_text}
               />
            ) : null}
         </div>
      </div>
   );
   const { isMicrositio, micrositioName } = verifyMicrositio(router.pathname);

   if (banner?.url) {
      return (
         <Link href={`${isMicrositio ? `/${micrositioName}` : ''}/${banner.url}`}>
            <a>
               <Banners />
            </a>
         </Link>
      );
   }

   return <Banners />;
}
