/* eslint-disable camelcase */
const verifyName = (arrayNames, name) => {
   return arrayNames.includes(name);
};

export default function getLastSlugFromChips(filters, targetFilter, name, value) {
   const { genero, slugs, marca, deporte } = filters;

   const isArray = Array.isArray(targetFilter);

   if (isArray) {
      const _targetFilter = targetFilter.filter((x) => x !== value);

      if (_targetFilter.length > 0) {
         return _targetFilter[_targetFilter.length - 1];
      }
   }

   if (deporte.length > 0 && verifyName(['talle'], name)) {
      return deporte[deporte.length - 1];
   }
   if (marca.length > 0 && verifyName(['deporte', 'talle'], name)) {
      return marca[marca.length - 1];
   }
   if (slugs?.length > 0 && verifyName(['marca', 'deporte', 'talle'], name)) {
      return slugs[slugs.length - 1];
   }
   return genero;
}
