import Breadcrumb from 'Breadcrumb';
import cn from 'classnames';
import BannerInteractive from '../../domain/BannerInteractive';
import Layout from '../../domain/Layout';
import ProductList from '../../domain/ProductList';
import FilterSort from '../../domain/Products/FilterSort';
import useDeleteTagDuplicate from '../../hooks/useDeleteTagDuplicate';
import styles from '../../styles/pages/products.module.scss';
import Text from '../Text';

export default function ProductosWrapper(props) {
   const { title, description, landingDescription } = props;

   const classContainer = cn({
      [styles.container]: true,
   });
   useDeleteTagDuplicate('description', 10000, 'meta');
   return (
      <Layout title={`${title}`} description={description}>
         <BannerInteractive />
         <Breadcrumb />
         {landingDescription && (
            <div style={{ width: '100%', margin: 'auto', maxWidth: '1400px' }}>
               <Text textStyle={{ gridColumn: '2/3', marginTop: '50px' }} maxLineLength={3}>
                  {landingDescription}
               </Text>
            </div>
         )}
         <div className={classContainer}>
            <FilterSort
               title={title.replace(/\|\s*Página\s*\d+/i, '').trim()}
               classFilter={styles.filter}
               classSort={styles.sort}
            />

            <div className={styles.productList}>
               <ProductList />
            </div>
         </div>
         <Breadcrumb onlyMobile />
      </Layout>
   );
}
