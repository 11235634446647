import React from 'react';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import FilterItem from '../FilterItem';
import styles from './filter.module.scss';

function Filter(props) {
   const { onClose, title, isTablet } = props;
   const { filtersOptions } = useSelector((state) => state.products);
   const renderFilterItems = () => {
      const types = {
         sizes: 'talle',
         gender: 'genero',
         brand: 'slugs',
         sport: 'slugs',
      };
      return (
         <ul>
            {Object.keys(filtersOptions)
               .filter((option) => filtersOptions[option].options.length > 0)
               .map((option) => (
                  <FilterItem
                     key={option}
                     label={filtersOptions[option].name}
                     options={filtersOptions[option].options}
                     type={types[option] || 'slugs'}
                     multiple={option !== 'gender'}
                     sizes={option === 'sizes'}
                  />
               ))}
         </ul>
      );
   };
   return (
      <aside className={styles.container}>
         <div className={styles.close}>
            <button onClick={onClose}>
               <MdClose size={24} />
            </button>
         </div>

         <div className={styles.content}>
            {!isTablet && <h1 className={styles.title}>{title}</h1>}
            <span className={styles.title}>Filtrar por</span>
            {renderFilterItems()}
         </div>
      </aside>
   );
}

Filter.propTypes = {};

export default Filter;
