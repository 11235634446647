import React, { useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import cn from 'classnames';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { removeFilter, setFilter } from '../../../../redux';
import routerPushWithQuery from '../../../../services/filters/routerPushWithQuery';
import FilterOption from '../FilterOption';
import FilterOptionSize from '../FilterOptionSize';
import styles from './filter-item.module.scss';

export default function FilterItem(props) {
   const { label, options, type, multiple, sizes } = props;
   const dispatch = useDispatch();
   const { orderBy } = useSelector((state) => state.products);
   const [open, setOpen] = useState(true);
   const isMobile = useMediaQuery('(max-width: 768px)');
   const renderValue = () => {
      return options
         .filter((x) => x.selected)
         .map((x) => x.name)
         .join(' / ');
   };

   const handleClick = () => {
      setOpen(!open);
   };

   // al hacer click en un filtro
   const handleClickOption = async (slug, selected) => {
      // si es mobile, scrolleamos al inicio de la pagina
      if (isMobile) {
         window.scrollTo(0, 282);
      }

      let result;

      // al ejecutar la accion de redux, guardamos el objeto que devuelve dispatch()
      // asi podemos acceder a los filtros activos (incluido el que se hizo click)
      if (!selected) {
         result = await dispatch(setFilter(type, slug, multiple));
      } else {
         result = await dispatch(removeFilter(type, slug, multiple, label));
      }

      // hacemos el push a la nueva pagina con los filtros aplicados

      routerPushWithQuery(result.filtersActive, orderBy);
   };

   const renderOptions = () => {
      return options.map((x) => {
         if (sizes) {
            return (
               <FilterOptionSize
                  key={x.slug}
                  onClick={(selected) => handleClickOption(x.slug, selected)}
                  {...x}
               />
            );
         }

         return (
            <FilterOption
               key={x.slug}
               onClick={(selected) => handleClickOption(x.slug, selected)}
               {...x}
            />
         );
      });
   };

   const classList = cn({
      [styles.list]: true,
      [styles['list--row']]: !!sizes,
   });

   return (
      <li className={styles.container}>
         <button className={styles.top} onClick={handleClick}>
            <div className={styles.labelValue}>
               <span className={styles.label}>{label}</span>
               <span className={styles.value}>{renderValue()}</span>
            </div>

            {open && <MdKeyboardArrowUp size={24} />}
            {!open && <MdKeyboardArrowDown size={24} />}
         </button>

         {open && <ul className={classList}>{renderOptions()}</ul>}
      </li>
   );
}
