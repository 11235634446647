import verifyMicrositio from './verifyMicrositio';

// creamos el pathname y la URL que se muestra en el browser a partir de los filtros activos
export default function createQueryURL(newQuery, filters) {
   const { genero, slugs, talle, ordenar, keywords, landing, page } = newQuery;
   let marcas = filters?.marca;
   let deportes = filters?.deporte;
   if (slugs?.length > 0) {
      marcas = filters?.brand?.options
         ?.filter((b) => slugs.includes(b.slug))
         .map((b) => b.slug)
         .concat(marcas);
      deportes = filters?.sport?.options
         ?.filter((b) => slugs.includes(b.slug))
         .map((b) => b.slug)
         .concat(deportes);
   }
   let newUrl = '';
   const currentPathname = window.location.pathname;

   // verificamos si estamos actualmente en un micrositio
   const { isMicrositio, micrositioName } = verifyMicrositio(currentPathname);

   // si es micrositio la URL va a comenzar con /city (ejemplo)
   if (isMicrositio) {
      newUrl += `/${micrositioName}`;
   }

   if (landing) {
      newUrl += `/${landing}`;
   }

   // si el filtro de genero esta aplicado, insertamos en la URL el genero
   // y tambien vamos armando el pathname
   if (genero) {
      newUrl += `/${genero}`;
   }
   if (!genero && !landing) {
      // si no hay genero aplicado, entonces la URL va a comenzar con /productos...
      newUrl += '/productos';
   }
   // IDEM
   if (slugs?.length > 0) {
      newUrl += `/${slugs.join('/')}`;
   } else {
      if (marcas.length > 0) {
         newUrl += `/${marcas.join('/')}`;
      }
      if (deportes.length > 0) {
         newUrl += `/${deportes.join('/')}`;
      }
   }

   // este array va a guardar los filtros que van a pasar a ser URL query
   const query = [];

   if (talle?.length > 0) {
      // al hacer .toString se van a separar por "," porque es un array
      newUrl += `/t/${talle.toString()}`;
   }
   if (ordenar) {
      newUrl += `/o/${ordenar}`;
   }
   if (keywords) {
      newUrl += `/k/${keywords}`;
   }
   if (page >= 1) {
      newUrl += `/p/${page}`;
   }

   // verificamos si hay almenos 1 query, si no directamente no lo agregamos a la URL
   if (query.length > 0) {
      // creamos el pathname y URL final
      // separamos cada query en el array por "&"
      newUrl = `${newUrl}?${query.join('&')}`;
   }
   return {
      newUrl,
   };
}
