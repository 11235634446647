const getPageNumber = (current, total, type) => {
   let page;

   if (type === 'BACK') {
      if (current - 1 < 1) {
         page = 1;
      } else {
         page = current - 1;
      }
   }

   if (type === 'NEXT') {
      if (current + 1 > total) {
         page = total;
      } else {
         page = current + 1;
      }
   }

   return page;
};

export default getPageNumber;
