import React from 'react';
import Placeholder from 'Placeholder';
/* eslint-disable react/no-array-index-key */
import styles from './products-skeleton.module.scss';

const ProductsSkeleton = () => {
   return Array(15)
      .fill()
      .map((item, index) => (
         <li key={index} className={styles.container}>
            <Placeholder />
         </li>
      ));
};

export default ProductsSkeleton;
