/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import React from 'react';
import classnames from 'classnames/bind';
import { useRouter } from 'next/router';
import { MdChevronRight, MdChevronLeft, MdMoreHoriz } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { pageNext, pageMoreNext, pageBack, pageMoreBack, setPage } from '../../redux';
import PaginationButton from './PaginationButton';
import PaginationItem from './PaginationItem';
import styles from './pagination.module.scss';

const cx = classnames.bind(styles);

function Pagination({ pagination }) {
   const router = useRouter();
   const dispatch = useDispatch();

   const handleClickArrow = (type) => {
      switch (type) {
         case 'NEXT':
            dispatch(pageNext());
            break;
         case 'MORE_NEXT':
            dispatch(pageMoreNext());
            break;
         case 'BACK':
            dispatch(pageBack());
            break;
         case 'MORE_BACK':
            dispatch(pageMoreBack());
            break;
         default:
            break;
      }
   };

   const handleClickPage = (page) => {
      dispatch(setPage(page));
   };

   const getStartEndPagination = (range, totalPages, currentPage) => {
      let startPage = 1;

      // sirve para evitar valores negativos, fuerza empezar en 1
      if (currentPage < range / 2 + 1) {
         startPage = 1;
         // sirve para no indexiar malal final
      } else if (currentPage >= totalPages - range / 2) {
         startPage = Math.floor(totalPages - range + 1);
      } else {
         startPage = currentPage - Math.floor(range / 2);
      }

      const endPage = startPage + range - 1;

      return { startPage, endPage };
   };

   const renderItemsPagination = () => {
      let range = 5;
      const totalPages = pagination.total;
      const currentPage = pagination.current;

      if (totalPages <= range) {
         range = totalPages;
      }

      const { startPage, endPage } = getStartEndPagination(range, totalPages, currentPage);

      const pagingItems = [];
      for (let i = startPage; i <= endPage; i++) {
         pagingItems.push(
            <PaginationItem
               key={i}
               page={i}
               onClick={handleClickPage}
               active={pagination.current === i}
               router={router}
            />,
         );
      }
      return pagingItems;
   };

   return (
      <ul className={styles.container}>
         <PaginationButton icon={<MdChevronLeft />} type="BACK" onClick={handleClickArrow} />

         {pagination.current > 3 && (
            <div className={cx('item', 'item--dots')}>
               <MdMoreHoriz />
            </div>
         )}

         {renderItemsPagination()}

         {pagination.current !== pagination.total && pagination.current < pagination.total - 2 && (
            <div className={cx('item', 'item--dots')}>
               <MdMoreHoriz />
            </div>
         )}

         <PaginationButton icon={<MdChevronRight />} type="NEXT" onClick={handleClickArrow} />
      </ul>
   );
}

export default Pagination;
