import { useEffect } from 'react';
import ProductDetailWrapper from 'Pages/ProductDetailWrapper';
import ProductosWrapper from 'Pages/ProductsWrapper';
import { useSelector } from 'react-redux';
import api from '../../api';
import useTrackingQueue from '../../hooks/useTrackingQueue';
import getBuildTime from '../../services/build/getBuildTime';
import capitalize from '../../services/capitalize';
import { destroyCookie, getCookies } from '../../services/cookies';
import createUUID from '../../services/createUUID';
import getLastSlug from '../../services/filters/getLastSlug';
import getQuerysOfSlugs from '../../services/getQuerysOfSlugs';
import getServerSideURL from '../../services/getServerSideURL';
import getSlugsWithoutQuerys from '../../services/getSlugsWithoutQuerys';
import generateLinkHref from '../../services/page/generateLinkHref';
import getInitialReduxState from '../../services/page/getInitialReduxState';
import isValidPage from '../../services/page/isValidPage';

export default function Products(props) {
   const { genero, marca, deporte, landing, keywords, initialReduxState, landingInfo } = props;
   const isProductDetail = initialReduxState.productDetail;

   const { orderBy } = useSelector((state) => state.products);
   const { executeEvent } = useTrackingQueue();

   useEffect(() => {
      if (landing) {
         const eventId = createUUID();
         executeEvent('viewLanding', { eventId, landingInfo });
      }
   }, []);

   const value = capitalize(`${marca || ''}-${deporte || ''}-${genero || ''}`, '-');
   const productsSelected = initialReduxState.products?.filtersOptions;
   const findTypeOfProductSelected = (type) => {
      return productsSelected?.[type].options
         .filter((o) => o.selected)
         .map((o) => o.name)
         .join(', ');
   };
   const business = findTypeOfProductSelected('business');
   const brand = findTypeOfProductSelected('brand');
   const kind = findTypeOfProductSelected('kind');
   const sport = findTypeOfProductSelected('sport');
   const renderTitle = () => {
      const allProductsTitle = () => {
         switch (orderBy) {
            case 'ofertas':
               return '¡Comprá nuestras Ofertas, Promociones y Descuentos!';
            case 'lanzamientos':
               return '¡Comprá nuestros Lanzamientos!';
            default:
               return 'Listado de Productos';
         }
      };
      const landingOrKeywordTitle = (withGender) => {
         if (withGender) {
            return `Comprá productos${keywords ? ` de ${capitalize(keywords)}` : ''} ${
               landing ? ` de ${capitalize(landing, '-')}` : ''
            } para ${capitalize(genero)}`;
         }
         return `Comprá lo mejor de ${capitalize(landing, '-') || capitalize(keywords)}`;
      };
      const isDifferentToKeyword = (option) => {
         return option && option.toLowerCase() !== keywords?.toLowerCase();
      };
      const slugsTitle = () => {
         return `Comprá ${
            keywords
               ? ` lo mejor de ${capitalize(keywords)} ${
                    isDifferentToKeyword(brand) || isDifferentToKeyword(sport) ? 'en' : ''
                 } `
               : ''
         } ${landing ? ` lo mejor de ${capitalize(landing, '-')} en ` : ''}${
            (isDifferentToKeyword(kind) ? kind : '') ||
            (isDifferentToKeyword(business) ? business : '')
         } ${isDifferentToKeyword(brand) ? brand : ''}${
            isDifferentToKeyword(sport) ? ` de ${sport}` : ''
         }${value && value.trim() ? ` para ${value}` : ''}`;
      };
      if (!kind && !business && !brand && !sport) {
         return landing || keywords ? landingOrKeywordTitle(!!genero) : allProductsTitle();
      }

      return slugsTitle();
   };
   const renderDescription = () => {
      return `Descubre una amplia gama de productos en Vaypol y encuentra ${
         brand ? `${brand},` : ''
      } ${sport ? `${sport},` : ''} ${genero ? `${capitalize(genero)},` : ''} ${
         kind ? `${kind}` : ''
      }  y ${business ? `${business},` : ''} para cada estilo y necesidad | Página ${
         initialReduxState.products?.pagination.current
      }`;
   };

   return isProductDetail ? (
      <ProductDetailWrapper storeName={process.env.NEXT_PUBLIC_STORE_NAME} {...props} />
   ) : (
      <ProductosWrapper
         title={`${renderTitle()} | Página ${initialReduxState.products?.pagination.current}`}
         description={renderDescription()}
         landingDescription={landingInfo.description}
      />
   );
}

export async function getServerSideProps(
   context,
   genero = '',
   storeId = process.env.NEXT_PUBLIC_STORE_ID,
) {
   const slug = context.params?.slug;
   if (slug) {
      const [id] = slug
         .replace(/[^0-9--]/g, '')
         .split('-')
         .slice(-1);
      if (id.length === 8 && id[0] == 4) {
         const { 1: idColorFromOldUrl } = slug
            .replace(/[^0-9--]/g, '')
            .split('-')
            .slice(-2);
         return {
            redirect: {
               destination: slug.replace(`-${idColorFromOldUrl}`, ''),
               permanent: true,
            },
         };
      }
      if (id) {
         const startTime = new Date();

         const eventId = createUUID();

         const initialReduxState = await getInitialReduxState(storeId);

         const product = await api.products.getByID(id, eventId);
         const installments = await api.installments.getAllPromos(storeId, id);
         if (!product.success) {
            throw new Error(product.error);
         }

         const { idColor } = getCookies(context);
         let colorSelected;
         if (idColor) {
            colorSelected = product.data?.colors.find((c) => c.id === Number(idColor));
            destroyCookie('idColor', context);
         } else {
            colorSelected = product.data?.colors[0];
         }
         if (colorSelected === undefined) {
            return {
               redirect: {
                  destination: '/',
                  permanent: false,
               },
            };
         }
         return {
            props: {
               buildInfo: {
                  generatedAt: startTime.toLocaleTimeString(),
                  execTime: getBuildTime(startTime),
                  path: '/product-detail',
               },
               initialReduxState: {
                  ...initialReduxState,
                  productDetail: {
                     id,
                     slug,
                     product: product.data,
                     colorSelected,
                     variantSelected: null,
                     quantity: 0,
                     eventId,
                     installments: installments.data,
                  },
               },
            },
         };
      }
   }
   context.res.setHeader('Cache-Control', 's-maxage=1, stale-while-revalidate');

   const landing = slug || context.params?.slugs?.[0];
   const isLanding = await api.products.validateSlug(storeId, landing);
   isLanding.name = landing;
   const { finalUrl } = getServerSideURL(
      context.query,
      context.params,
      genero,
      context.resolvedUrl,
      isLanding.landing,
   );
   if (context.query) {
      const {
         page: pageInQuery,
         keywords: keywordsInQuery,
         talle: talleInQuery,
         ordenar: ordenarInQuery,
         marca: marcaQuery,
         deporte: deporteQuery,
      } = context.query;
      // Page tiene una comprobación extra ya que cuando es landing page se pega en context.params

      if (
         (pageInQuery && !context.resolvedUrl.match(/\/p\//)) ||
         keywordsInQuery ||
         talleInQuery ||
         ordenarInQuery ||
         (genero && marcaQuery) ||
         (genero && deporteQuery)
      ) {
         const queryIndex = finalUrl.indexOf('?') >= 0 ? finalUrl.indexOf('?') : finalUrl.length;

         const queryInParamsIndex =
            finalUrl.search(/\/o\/|\/k\/|\/p\//) >= 0 ? finalUrl.search(/\/o\/|\/k\/|\/p\//) : 0;

         return {
            redirect: {
               destination: `${finalUrl.slice(0, queryInParamsIndex)}${
                  genero && marcaQuery ? `/${marcaQuery.replace(/,/g, '/')}` : ''
               }${
                  genero && deporteQuery ? `/${deporteQuery.replace(/,/g, '/')}` : ''
               }${finalUrl.slice(queryInParamsIndex, queryIndex)}${
                  keywordsInQuery
                     ? `${finalUrl[queryIndex - 1] === '/' ? 'k/' : '/k/'}${keywordsInQuery}`
                     : ''
               }${
                  talleInQuery
                     ? `${finalUrl[queryIndex - 1] === '/' ? 't/' : '/t/'}${talleInQuery}`
                     : ''
               }${
                  ordenarInQuery
                     ? `${finalUrl[queryIndex - 1] === '/' ? 'o/' : '/o/'}${ordenarInQuery}`
                     : ''
               }${
                  pageInQuery
                     ? `${finalUrl[queryIndex - 1] === '/' ? 'p/' : '/p/'}${pageInQuery}`
                     : ''
               }${!genero && marcaQuery ? `?marca=${marcaQuery}` : ''}${
                  !genero && deporteQuery ? `${marcaQuery ? '&' : '?'}deporte=${deporteQuery}` : ''
               }`,
               permanent: true,
            },
         };
      }
   }

   const querysTransformedToSlugs = getQuerysOfSlugs(context.params?.slugs);
   const page = querysTransformedToSlugs?.page || context.query?.page || 1;

   if (context.params?.slugs) {
      const { slugs } = context.params;
      if (slugs[slugs.length - 2] !== 'p') {
         const urlWithoutPage = finalUrl.replace(new RegExp(`/p/${page}`), '');
         const queryIndex =
            urlWithoutPage.indexOf('?') >= 0 ? urlWithoutPage.indexOf('?') : urlWithoutPage.length;
         return {
            redirect: {
               destination: `${urlWithoutPage
                  .replace(new RegExp(`/p/${page}`), '')
                  .slice(0, queryIndex)}/p/${page}${urlWithoutPage.slice(queryIndex)}`,
               permanent: true,
            },
         };
      }
   }

   const validationPage = isValidPage(finalUrl, '/productos/', isLanding.landing, genero);
   if (!validationPage.isValid) {
      return {
         redirect: {
            destination: validationPage.redirectTo,
            permanent: false,
         },
      };
   }
   if (page === 1 && !/page=1|\/p\/1/.test(decodeURIComponent(context.resolvedUrl))) {
      const queryIndex = finalUrl.indexOf('?') >= 0 ? finalUrl.indexOf('?') : finalUrl.length;

      return {
         redirect: {
            destination: `${finalUrl.slice(0, queryIndex)}${
               finalUrl[queryIndex - 1] === '/' ? 'p/' : '/p/'
            }${page}${finalUrl.slice(queryIndex)}`,
            permanent: true,
         },
      };
   }

   const initialReduxState = await getInitialReduxState(storeId);

   if (
      initialReduxState.site.main.storeId !== storeId &&
      !initialReduxState.site.micrositios.find((m) => m.storeId === storeId)
   ) {
      const incorrectPath = `/${storeId}`;

      return {
         redirect: {
            destination: context.resolvedUrl.replace(incorrectPath, ''),
            permanent: false,
         },
      };
   }
   // guardamos los filtros que vienen en la URL
   // los que son de dynamic route, estan en context.params
   // los que son query normales, estan en context.query
   const slugsWithoutQuerys =
      getSlugsWithoutQuerys(
         context.params?.slugs,
         querysTransformedToSlugs.slugsRegexToDelete,
         isLanding.landing ? landing : null,
      ) || [];
   const filtros = {
      genero,
      slugs: slugsWithoutQuerys,
      marca: context.query.marca?.split(',') || [],
      deporte: context.query.deporte?.split(',') || [],
      talle: querysTransformedToSlugs.talle?.split(',') || context.query.talle?.split(',') || [],
      keywords:
         querysTransformedToSlugs.keywords?.replace(/,/g, '/').replace(/-/g, ' ') ||
         context.query.keywords?.replace(/-/g, ' ') ||
         '',
      ordenar: querysTransformedToSlugs.ordenar || context.query.ordenar || '',
      landing: isLanding.landing ? landing : '',
   };
   // obtenemos el ultimo filtro que se ha aplicado y lo guardamos
   filtros.last_slug = getLastSlug(filtros, null);
   // hacemos la llamada a la API para obtener los productos con los filtros y numero de pagina
   const result = await api.products.get(filtros, page, storeId);
   // Redirección a la última página de productos disponibles
   if (
      result.data?.total_count !== 0 &&
      result.success &&
      result.data?.total_pages < (querysTransformedToSlugs?.page || context.query?.page)
   ) {
      const pathWithUltimatePage = context.req.url.replace(
         /p\/([^&]*)/,
         `p/${result.data.total_pages}`,
      );
      return {
         redirect: {
            destination: pathWithUltimatePage,
            permanent: false,
         },
      };
   }
   // Redirección a la última página de productos disponibles si el link no coincide
   const correctUrl = context.req.url.replace(
      /\/\[slug\]|\/\[unidadNegocio\]|\[...tipoProducto\]|\[token\]|\[id\]/g,
      '',
   );
   if (correctUrl !== context.req.url) {
      return {
         redirect: {
            destination: correctUrl || '/',
            permanent: false,
         },
      };
   }
   // si se produjo un error, lo redireccionamos a la vista de "no resultados"

   if (!result.success || result.data.total_count === 0) {
      return {
         redirect: {
            destination: generateLinkHref('no-hay-resultados', {
               pathname: context.resolvedUrl,
            }),
            permanent: false,
         },
      };
   }

   return {
      props: {
         initialReduxState: {
            ...initialReduxState,

            // hidratamos el estado en redux
            products: {
               // productos
               items: result.data.products,
               // filtros disponibles
               filtersOptions: result.data.filters,
               // filtros que estan activos
               filtersActive: {
                  slugs: [],
                  ...filtros,
               },
               orderBy: filtros.ordenar,
               pagination: {
                  total: result.data.total_pages,
                  // si el numero de pagina actual es menor a la totalidad de paginas
                  // lo reseteamos a 1
                  current: result.data.total_pages >= page ? Number(page) : 1,
               },
               loading: false,
            },
         },
         landing: isLanding.landing ? landing : '',
         landingInfo: isLanding,
         genero: genero === 'nino' ? 'Niño' : genero,
         keywords: (querysTransformedToSlugs.keywords || context.query.keywords) ?? null,
      },
   };
}
