import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { MdClose, MdArrowDropUp, MdArrowDropDown } from 'react-icons/md';
import { useSelector } from 'react-redux';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import SortItem from '../SortItem';
import styles from './sort.module.scss';

const ButtonAction = (props) => {
   const { label, open, onClick } = props;
   const ref = useRef(null);

   useOutsideClick(ref, () => {
      if (open) {
         onClick();
      }
   });

   const renderLabel = () => {
      switch (label) {
         case 'menor_precio':
            return 'Menor precio';
         case 'mayor_precio':
            return 'Mayor precio';
         case 'ofertas':
            return 'Ofertas';
         case 'lanzamientos':
            return 'Lanzamientos';
         default:
            return 'Ordenar por';
      }
   };

   return (
      <button ref={ref} className={styles.btnAction} onClick={onClick}>
         <span>{renderLabel()}</span>
         {open && <MdArrowDropUp size={20} />}
         {!open && <MdArrowDropDown size={20} />}
      </button>
   );
};

export default function Sort(props) {
   const { onClose } = props;
   const { orderBy } = useSelector((state) => state.products);
   const [open, setOpen] = useState(false);

   const handleClick = () => {
      setOpen(false);
      onClose();
   };

   const contentClass = cn({
      [styles.content]: true,
      [styles['content--visible']]: open,
   });

   return (
      <div className={styles.container}>
         <ButtonAction label={orderBy} open={open} onClick={() => setOpen(!open)} />

         <div className={styles.close}>
            <button onClick={onClose}>
               <MdClose size={24} />
            </button>
         </div>

         <div className={contentClass}>
            <span className={styles.title}>Ordenar por</span>

            <ul>
               <SortItem label="Menor precio" value="menor_precio" onClick={handleClick} />
               <SortItem label="Mayor precio" value="mayor_precio" onClick={handleClick} />
               <SortItem label="Ofertas" value="ofertas" onClick={handleClick} />
               <SortItem label="Lanzamientos" value="lanzamientos" onClick={handleClick} />
            </ul>
         </div>
      </div>
   );
}
