/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import styles from './filter-option.module.scss';

export default function FilterOption(props) {
   const { name, count, onClick } = props;
   const [selected, setSelected] = useState(props.selected);

   useEffect(() => setSelected(props.selected), [props.selected]);

   const handleClick = () => {
      setSelected(!selected);
      onClick(selected);
   };

   const classNameIcon = cn({
      [styles.icon]: true,
      [styles['icon--checked']]: selected,
   });

   return (
      <li>
         <button className={styles.container} onClick={handleClick}>
            <div className={classNameIcon}>
               {!selected && <MdCheckBoxOutlineBlank size={20} />}
               {selected && <MdCheckBox size={20} />}
               <div className={styles.tickFill} />
            </div>

            <span className={styles.name}>
               {name} ({count})
            </span>
         </button>
      </li>
   );
}
